// Tailwind Style
@tailwind base;
@tailwind components;
@tailwind utilities;

/* latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
body {
  font-family: "Inter", sans-serif;
}
html {
  overflow: auto;
  padding-right: 0;
  font-family: "Inter", sans-serif;
}

@layer base {
  // .btn-primary {
  //   @apply h-11 rounded-full border border-primary500 bg-white px-6 text-sm font-medium text-primary100 hover:bg-primary500  hover:text-white focus:outline-none disabled:opacity-40 2xl:h-10 2xl:px-6;
  // }
  // .btn-primary-outline {
  //   @apply flex h-11 items-center rounded-full border border-white px-6 text-xs font-medium text-white hover:border-primary hover:bg-primary hover:text-white;
  // }
  // .btn-secondary-outline {
  //   @apply flex h-7.5 items-center rounded-full border border-primary px-3.5 text-xs font-medium text-primary hover:bg-primary hover:text-white;
  // }
  // .flexCenter {
  //   @apply flex items-center;
  // }
  .custom-buttons {
    label {
      @apply bg-transparent border-none text-sm text-black100 leading-4.5 font-medium py-4;
      &:hover,
      &:active,
      &:focus {
        @apply text-black100;
        background: none !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
      }
    }
    .active {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      background: #ffffff !important;
      box-shadow: 0px 0px 8px rgba(192, 10, 14, 0.25);
      border-radius: 6px !important;
      color: #dc143c !important;
      &:hover,
      &:active,
      &:focus {
        background: #ffffff !important;
        color: #dc143c !important;
      }
    }
  }
  .input-grids {
    .input-card:nth-child(4n) {
      border: none;
    }
  }
  .delete-button {
    outline: none !important;
    &:hover {
      svg {
        [class*="trash-lid"] {
          transform: rotate(0) translate(0, 0);
        }
      }
    }

    svg {
      width: 16px;
      height: 16px;
      color: #c00a0e;
      [class*="trash-lid"] {
        transform: rotate(-7deg) translate(-0.025rem, 3px);
        transition: 0.1s all;
      }
    }
  }
  .submit-button {
    &:focus {
      outline: none !important;
    }
  }
  .Modal-container {
    font-family: "inter" !important;
    width: 400px !important;
    .ant-modal-content {
      padding: 0 !important;
    }
    .ant-modal-header {
      padding: 18px 24px;
      background-color: #000000;
      margin-bottom: 0 !important;
    }
    .ant-modal-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      font-family: "Inter" !important;
    }
    .ant-modal-close {
      color: #ffffff;
      top: 12px !important;
      &:hover {
        color: #ffffff;
      }
    }
    button {
      font-family: "Inter" !important;
      &:active,
      &:focus,
      &:hover {
        outline: none !important;
      }
    }
  }
  .tabs-Details {
    .ant-tabs-nav {
      margin-bottom: 24px;
      padding-left: 32px;
      &::before {
        border: none !important;
      }
      .ant-tabs-nav-list {
        border-bottom: 2px solid #ffeeee;
        .ant-tabs-tab {
          padding: 0 28px 11px 28px !important ;
          font-family: "Inter";
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #0e150e;
        }
        .ant-tabs-tab-active {
          border-bottom: 2px solid #dc143c;
          .ant-tabs-tab-btn {
            font-weight: 600;
            color: #dc143c;
          }
        }
      }
      .ant-tabs-ink-bar {
        background: #dc143c !important;
        border-radius: 12px 12px 0px 0px;
        height: 3px;
      }
    }
  }
  .extraction-table {
    padding-left: 32px;
    .ant-table {
      border: 1px solid #d7e5ef;
      border-radius: 8px !important;
      thead {
        tr {
          th {
            background: #f4fbff;
            padding: 28px 16px;
            font-family: "Inter";
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #645e5e;
            border-width: 0px 1px 1px 0px;
            border-style: solid;
            border-color: #d7e5ef;
            &::before {
              display: none;
            }
          }
          td {
            background: #f4fbff;
            border-width: 0px 0px 1px 0px;
            border-style: solid;
            border-color: #d7e5ef;
          }
        }
      }
      tbody {
        tr {
          td {
            border-width: 0px 1px 0px 0px;
            border-style: solid;
            border-color: #d7e5ef;
            padding: 12px 40px 12px 16px;
            &:last-child {
              border-width: 0px !important;
              padding: 12px 16px !important;
            }
            .ant-tag {
              padding: 8px !important;
              background: #edf3ff;
              border: 1px solid #c4d8ff;
              border-radius: 24px;
              height: 100%;
              font-family: "Inter";
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: #0e150e;
            }
            font-family: "Inter";
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #0e150e;
          }
          &:last-child {
            &:hover {
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }
        }
      }
    }
    .ant-pagination {
      margin: 20px 0px !important;
      color: #000000;
      li {
        button {
          padding: 12px !important;
          background: #ffffff;
          border: 1px solid #f1f1f1 !important;
          border-radius: 8px !important;
          height: 32px !important;
          display: flex !important;
          align-items: center;
        }
        a {
          padding: 12px !important;
          background: #ffffff;
          border: 1px solid #f1f1f1 !important;
          border-radius: 8px !important;
          height: 32px !important;
          display: flex !important;
          align-items: center;
        }
      }
      .ant-pagination-item-active {
        background: #dc143c !important;
        border-radius: 8px;
        color: #ffffff !important;
        border: none !important;
        a {
          border: none !important;
          background: unset !important;
        }
      }
    }
  }
  .export-button {
    font-family: "inter" !important;
  }
  .value-add-buttons {
    &:focus,
    &:active,
    &:hover {
      outline: none !important;
    }
  }
  .landing-page {
    @media screen and (max-height: 600px) {
      overflow: auto !important;
    }
    @media screen and (max-width: 1300px) {
      overflow: auto !important;
    }
  }
  .image-animation {
    animation-duration: 2s;
    // animation-iteration-count: ;
    transform-origin: bottom;
    animation-name: bounce-6;
    animation-timing-function: ease;
  }
  @keyframes bounce-6 {
    0% {
      transform: scale(1, 1) translateY(0);
    }
    10% {
      transform: scale(1.1, 0.9) translateY(0);
    }
    30% {
      transform: scale(0.9, 1.1) translateY(-100px);
    }
    50% {
      transform: scale(1.05, 0.95) translateY(0);
    }
    57% {
      transform: scale(1, 1) translateY(-7px);
    }
    64% {
      transform: scale(1, 1) translateY(0);
    }
    100% {
      transform: scale(1, 1) translateY(0);
    }
  }
  
    [class*="tippy-box"] {
      background-color: #ffffff !important;
      border-radius: 8px !important;
      box-shadow: 0px 4px 14px rgb(0 30 75 / 15%) !important;
    }
    [class*="tippy-arrow"] {
      color: #fff !important;
    }
    [class*="tippy-content"] {
      padding: 18px 12px 23px 16px !important;
    }
}
